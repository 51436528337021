import React from "react";

function ToggleDashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      data-name="Component 11 – 2"
      viewBox="0 0 21 17"
    >
      <rect
        width="12"
        height="3"
        data-name="Rectangle 12"
        rx="1.5"
        transform="translate(0 14)"
      ></rect>
      <rect
        width="13"
        height="3"
        data-name="Rectangle 12"
        rx="1.5"
        transform="translate(8)"
      ></rect>
      <rect
        width="21"
        height="3"
        data-name="Rectangle 13"
        rx="1.5"
        transform="translate(0 7)"
      ></rect>
    </svg>
  );
}

export default ToggleDashboardIcon;
