import React from 'react';


function SpotifyIcon() {
  return (
    <svg id="_62.spotify" data-name="62.spotify" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30">
      <path id="background" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" fill="#12c64b" />
      <g id="icon" transform="translate(7.426 9.839)">
        <path id="Path_24399" data-name="Path 24399" d="M55.794,105.418a.736.736,0,0,1-1.006.264,10.917,10.917,0,0,0-5.7-1.272,14.164,14.164,0,0,0-2.958.39.736.736,0,0,1-.39-1.413A15.335,15.335,0,0,1,49,102.94a14.461,14.461,0,0,1,3.143.228,10.616,10.616,0,0,1,3.392,1.238.735.735,0,0,1,.261,1.012Z" transform="translate(-43.499 -95.859)" fill="#fff" />
        <path id="Path_24400" data-name="Path 24400" d="M53.379,82.561a.871.871,0,0,1-.753.432.859.859,0,0,1-.44-.12,12.823,12.823,0,0,0-6.756-1.5,16.722,16.722,0,0,0-3.5.462.872.872,0,0,1-.462-1.68,18.075,18.075,0,0,1,3.864-.523,16.881,16.881,0,0,1,3.724.27,12.551,12.551,0,0,1,4.014,1.467A.872.872,0,0,1,53.379,82.561Z" transform="translate(-39.716 -75.845)" fill="#fff" />
        <path id="Path_24401" data-name="Path 24401" d="M48.228,56.5a1.067,1.067,0,0,1-1.463.383c-5.447-3.183-12.512-1.29-12.579-1.272a1.07,1.07,0,1,1-.565-2.062,22.2,22.2,0,0,1,4.728-.637,20.939,20.939,0,0,1,4.566.331,15.426,15.426,0,0,1,4.924,1.789,1.07,1.07,0,0,1,.389,1.468Z" transform="translate(-32.794 -52.897)" fill="#fff" />
      </g>
    </svg>


  )
}
export default SpotifyIcon;
