import React from "react";
import { Button, Form, notification } from "antd";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "store/actions/auth.action";
import InputComponent from "components/Input.component";

const ResetPasswordPage: React.FC = () => {
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [resetPasswordForm, setResetPasswordForm] = React.useState({
    password: "",
    passwordConfirm: "",
    token: "",
  });

  React.useEffect(() => {
    if (location?.state?.token == undefined) {
      navigate("/login");
    }
  }, [location]);

  const handleResetPassword = React.useCallback(
    (values: any) => {
      const formData = { ...resetPasswordForm, ...values, ...location.state };
      dispatch(resetPassword(formData, api, (e: any) => navigate('/login')))
    },
    [dispatch]
  );

  return (
    <div className="Login_auth fl_col jc_c">
      {contextHolder}

      <div className="form_header fl_col gp27 ai_c">
        <p className="p32 w-700 txt_center">Reset Password</p>
        <p className="p14 w-500 txt_center">
          Please enter your new password.
        </p>
      </div>

      <div className="form_body pd40-t inp_light ">
        <Form
          form={form}
          className="fl_col gp16"
          layout="vertical"
          onKeyUp={(e) => (e.key === "Enter" ? form.submit() : null)}
          initialValues={resetPasswordForm}
          onFinish={handleResetPassword}
        >
          <div className="fl_col gp20">
            <InputComponent
              type="password"
              placeholder="Password"
              name="password"
              required
              rules={[{ required: true, message: "password required" }]}
            />
            <InputComponent
              type="password"
              placeholder="Confirm Password"
              name="passwordConfirm"
              required
              rules={[{ required: true, message: "confirm password required" }]}
            />

            <Button onClick={() => form.submit()} className="btn-black">
              Reset password
            </Button>
          </div>
        </Form>
      </div>

      <div className="form_footer fl_col pd35-t-b">
        <Link className="p14 w-500 txt_center underline" to={"/login"}>
          Back to login
        </Link>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
