import axios from "axios"
import methods from "shared/utils/axios.util"

const authService = {

  login : async (data:any) => {
    return await methods.post('auth/login',data)
  },

  register: async (data:any) => {
    return await methods.post('auth/register',data)
  },

  forgot: async (data:any) => {
    return await methods.post('auth/forgot',data)
  },

  verifyCode: async (data:any) => {
    return await methods.post('auth/verify-code',data)
  },

  resendCode: async (data:any) => {
    return await methods.post('auth/resend-code',data)
  },
  
  resetPassword: async (data:any) => {
    return await methods.post('auth/reset-password',data)
  }

} 

export default authService