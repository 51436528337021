import LogoBlackIcon from "assets/icons/logo_black";
import LogoWhiteIcon from "assets/icons/logo_white";
import AuthSlideComponent from "components/authSlide.component";
import StackedCarouselComponent from "components/stackedCarousel.component";
import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import authToken from "shared/utils/authToken";
const LayoutAuth: React.FC = () => {

  const navigate = useNavigate()

  React.useEffect(() => {
    const token = authToken.get()
    token.then((userToken) => {
      if (userToken) return navigate('/dashboard')
    })
  }, [])

  return (
    <div className="auth-layout flex fl_col">
      <div className="layout-wrapper fl_col">
         <header className="layout-header">
          <div className="header-wrapper flex items-center">
            <div className="header-logo">
              <div className="logo-wrapper pd20">
                <Link to={"/"}><LogoBlackIcon /></Link>
                <AuthSlideComponent></AuthSlideComponent>
              </div>
            </div>
          </div>
        </header> 
        <main className="layout-content">
          <div className="content-wrapper fl_row">
            <div className="left_side fl-1">
            <AuthSlideComponent></AuthSlideComponent>
            </div>
            <div className="right_side jc_c ai_c fl-1">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LayoutAuth;
