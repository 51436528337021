
import { Outlet, useNavigate } from "react-router-dom";
import LeftNavbar from "./_partials/dashboard/leftNavbar";
import TopNavbar from "./_partials/dashboard/topNavbar";
import React from "react";
import authToken from "shared/utils/authToken";

const DashboardLayout: React.FC = () => {

  const navigate = useNavigate()
  const [loding,setLoading] = React.useState(true)

  React.useEffect(()=>{

    const token = authToken.get()

    /* token.then((userToken)=>{
      if(!userToken) return navigate('/')
      setLoading(false)
    }) */

  },[])

  return (
    <div className="" id="dashboard-layout">
      <div className="layout-wrapper">
        <div className="navbar-top">
          <TopNavbar />
        </div>
        <div className="body-wrapper">
          {/* <div className="navbar-left">
            <LeftNavbar />
          </div> */}
          <div className="body-content pd20 fl_col gp20">
            {/* <p className="p20 w-500 title_tab">Users</p> */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
