import React from "react";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import LogoWhiteIcon from "assets/icons/logo_white";

const AuthSlideComponent = () => {
  return (
    <div className="AuthSlider-elements w-full h-full">
      <Swiper
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_1.png`)}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_2.png`)}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_3.png`)}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_4.png`)}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_5.png`)}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_7.png`)}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="img-slide"
            src={require(`./../assets/imgs/auth/auth_slide_8.png`)}
            alt=""
          />
        </SwiperSlide>
      </Swiper>
      <div className="overlayslider"></div>
      <div className="swiperFooter txt_center txt_white fl_col gp10">
        <div className="fl_col gp35 ai_c">
          <LogoWhiteIcon></LogoWhiteIcon>
          <p className="p14">Unlock the door to unforgettable moments!</p>
        </div>
        <p className="p28">Join the experience!</p>
      </div>
    </div>
  );
};

export default AuthSlideComponent;
