import React from "react";

function ItunesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      data-name="Group 2227"
      viewBox="0 0 30 30"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.713"
          x2="0.231"
          y1="0.879"
          y2="0.169"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#FB5C74"></stop>
          <stop offset="1" stopColor="#FA233B"></stop>
        </linearGradient>
      </defs>
      <path
        fill="#FB5C74"
        d="M15 0A15 15 0 110 15 15 15 0 0115 0z"
      ></path>
      <g transform="translate(7.083 5.379)">
        <g data-name="Group 2226">
          <path
            fill="#fff"
            fillRule="#FB5C74"
            d="M84.368 54.959c-.063.006-.62.1-.687.118l-7.712 1.556a1.075 1.075 0 00-.481.216.784.784 0 00-.259.5 1.757 1.757 0 00-.017.261v9.652a.934.934 0 01-.171.631.875.875 0 01-.563.288l-.5.1a8.8 8.8 0 00-1.427.361 2.24 2.24 0 00-.842.541 2.053 2.053 0 00.1 2.9 1.943 1.943 0 00.936.48 3.446 3.446 0 001.392-.071 2.831 2.831 0 00.9-.332 2.263 2.263 0 00.71-.657 2.4 2.4 0 00.378-.89 4.47 4.47 0 00.086-.956v-8.369c0-.448.127-.566.489-.654 0 0 6.41-1.293 6.709-1.351.417-.08.614.039.614.476v5.715a.947.947 0 01-.156.643.875.875 0 01-.563.288l-.5.1a8.794 8.794 0 00-1.427.361 2.24 2.24 0 00-.842.541 2.017 2.017 0 00.1 2.9 1.943 1.943 0 00.936.476 3.459 3.459 0 001.392-.071 2.822 2.822 0 00.9-.328 2.263 2.263 0 00.71-.657 2.4 2.4 0 00.378-.89 4.454 4.454 0 00.072-.956v-12.24c-.004-.441-.238-.718-.655-.682z"
            data-name="Path 24685"
            transform="translate(-71.154 -54.956)"
          ></path>
        </g>
      </g>
    </svg>
  );
}
export default ItunesIcon;
