import VerifyPage from "pages/auth/Verify.page";
import ForgotPage from "pages/auth/forgot.page";
import LoginPage from "pages/auth/login.page";
import ProfileSelectPage from "pages/auth/profileSelect.page";
import RegisterPage from "pages/auth/register.page";
import ResetPasswordPage from "pages/auth/resetPassword.page";
import SsoPage from "pages/auth/sso.page";
import VerifiedAccountPage from "pages/auth/verifiedAccount.page";
import { RouteObject } from "react-router-dom";


const authRoutes: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/sso",
    element: <SsoPage />,
  },
  {
    path: "/profile",
    element: <ProfileSelectPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/forgot",
    element: <ForgotPage />,
  },
  {
    path: "/reset",
    element: <ResetPasswordPage />,
  },
  {
    path: "/verify",
    element: <VerifyPage />,
  },
  {
    path: "/verified",
    element: <VerifiedAccountPage />,
  },
];

export default authRoutes;
