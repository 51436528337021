import { NotificationInstance } from "antd/es/notification/interface";
import { Dispatch } from "react";
import authService from "shared/services/auth.service";
import authToken from "shared/utils/authToken";


export const login = (data: any, api?: NotificationInstance, onSuccessNavigate?:any|null, onFailureNavigate?:any|null) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await authService.login(data)
    if (res.data.error) {
      api?.error({ 
        message:'',
        description: res.data.message, 
        onClose:()=>onFailureNavigate?onFailureNavigate(res.data):{}
      })
    }
    if (!res.data.error) {
      authToken.set(res.data.data.token)
      api?.success({
        message:'',
        description: res.data.message, 
        onClose:()=>onSuccessNavigate?onSuccessNavigate(res.data):{}
      })
    }

  } catch (error:any) {

    api?.error({ 
      message:'',
      description: error.response.data.message[0],
      onClose:onFailureNavigate
    })
  }

}

export const register = (data: any, api?: NotificationInstance, onSuccessNavigate?:any|null, onFailureNavigate?:any|null) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await authService.register(data)
    if (res.data.error) {
      api?.error({ 
        message:'',
        description: res.data.message, 
        onClose:onFailureNavigate
      })
    }
    if (!res.data.error) {
      api?.success({
        message:'',
        description: res.data.message, 
        onClose:()=>onSuccessNavigate?onSuccessNavigate(res.data):{}
      })
    }

  } catch (error:any) {

    api?.error({ 
      message:'',
      description: error.response.data.message[0],
      onClose:onFailureNavigate
    })

  }

}

export const forgot = (data: any, api?: NotificationInstance, onSuccessNavigate?:any|null, onFailureNavigate?:any|null) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await authService.forgot(data)
    if (res.data.error) {
      api?.error({ 
        message:'',
        description: res.data.message, 
        onClose:onFailureNavigate
      })
    }
    if (!res.data.error) {
      api?.success({
        message:'',
        description: res.data.message, 
        onClose:()=>onSuccessNavigate?onSuccessNavigate(res.data):{}
      })
    }

  } catch (error:any) {

    api?.error({ 
      message:'',
      description: error.response.data.message[0],
      onClose:onFailureNavigate
    })

  }

}

export const verifyCode = (data: any, api?: NotificationInstance, onSuccessNavigate?:any|null, onFailureNavigate?:any|null) => async (dispatch: Dispatch<any>) => {

  try {

    console.log(onSuccessNavigate);
    

    const res = await authService.verifyCode(data)
    if (res.data.error) {
      api?.error({ 
        message:'',
        description: res.data.message, 
        onClose:onFailureNavigate
      })
    }
    if (!res.data.error) {
      api?.success({
        message:'',
        description: res.data.message, 
        onClose:()=>onSuccessNavigate?onSuccessNavigate(res.data):{}
      })
    }

  } catch (error:any) {

    api?.error({ 
      message:'',
      description: error.response.data.message[0],
      onClose:onFailureNavigate
    })

  }
}

export const resendCode = (data: any, api?: NotificationInstance, onSuccessNavigate?:any|null, onFailureNavigate?:any|null) => async (dispatch: Dispatch<any>) => {

  try {
    

    const res = await authService.resendCode(data)
    if (res.data.error) {
      api?.error({ 
        message:'',
        description: res.data.message, 
        onClose:onFailureNavigate
      })
    }
    if (!res.data.error) {
      api?.success({
        message:" ",
        description: res.data.message, 
        onClose:()=>onSuccessNavigate?onSuccessNavigate(res.data):{}
      })
    }

  } catch (error:any) {

    api?.error({ 
      message:'',
      description: error.response.data.message[0],
      onClose:onFailureNavigate
    })

  }

}

export const resetPassword = (data: any, api?: NotificationInstance, onSuccessNavigate?:any|null, onFailureNavigate?:any|null) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await authService.resetPassword(data)
    if (res.data.error) {
      api?.error({ 
        message:'',
        description: res.data.message, 
        onClose:onFailureNavigate
      })
    }
    if (!res.data.error) {
      api?.success({
        message:'',
        description: res.data.message, 
        onClose:()=>onSuccessNavigate?onSuccessNavigate(res.data):{}
      })
    }

  } catch (error:any) {

    api?.error({ 
      message:'',
      description: error.response.data.message[0],
      onClose:onFailureNavigate
    })

  }

}