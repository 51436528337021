import { IReduxAction } from "../../shared/interfaces/reduxAction.interface"

const initialState = {
  language: "fr",
  appTheme: "light",
  notification: true,
  direction:'ltr'
}

const appReducer = (state = initialState, action:IReduxAction) => {
  switch (action.type) {
    default:
      return {...state}
  }
}

export default appReducer