
import DashboardPage from "pages/private/dashboard.page";
import { RouteObject } from "react-router-dom";


const privateRoutes: RouteObject[] = [
  {
    index:true,
    element:<DashboardPage />
  }
];

export default privateRoutes;
