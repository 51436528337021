import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer, PersistConfig, persistStore } from 'redux-persist'
import thunk from "redux-thunk"
import storage from 'redux-persist/lib/storage'
import appReducer from "./reducers/app.reducer"
import userReducer from "./reducers/user.reducer"


const persistConfig:PersistConfig<any> = {
  key:"root",
  storage,
}

const reducers = combineReducers({
  app:appReducer,
  user:userReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const  store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false}).concat(thunk)
})

export const persistor = persistStore(store)