import React from "react";

function NotificationsDashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.036"
      height="23.615"
      viewBox="0 0 21.036 23.615"
    >
      <g data-name="bell (1)" transform="rotate(14.98 3.233 9.39)">
        <path
          d="M18.614 14.618a5.809 5.809 0 01-2.04-4.43V7.779A6.039 6.039 0 0011.43 1.8V.864a.857.857 0 10-1.715 0V1.8a6.039 6.039 0 00-5.143 5.979v2.41a5.815 5.815 0 01-2.047 4.437 1.515 1.515 0 00.975 2.661h14.146a1.515 1.515 0 00.968-2.668z"
          data-name="Path 25"
        ></path>
        <path
          d="M11.8 23.983A3.6 3.6 0 0015.278 21H8.326a3.6 3.6 0 003.474 2.983z"
          data-name="Path 26"
          transform="translate(-1.229 -2.763)"
        ></path>
      </g>
    </svg>
  );
}

export default NotificationsDashboardIcon;
