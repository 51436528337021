import LogoBlackIcon from "assets/icons/logo_black";
import NotificationsDashboard from "assets/icons/notifications.dashboard";
import SearchDashboard from "assets/icons/search.dashboard";
import ToggleDashboard from "assets/icons/toggle.dashboard";
import React from "react";

const TopNavbar: React.FC = () => {
  return (
    <div id="TopNavbar">
      <div className="nav-wrapper fl_row ai_c jc_s pd20-t-b">
        <div className="fl_row ai_c logo">
          <LogoBlackIcon></LogoBlackIcon>
        </div>
        <div className="fl_row ai_c gp15 nav">
        <SearchDashboard></SearchDashboard>
        <NotificationsDashboard></NotificationsDashboard>
        <ToggleDashboard></ToggleDashboard>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
