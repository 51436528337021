import React from 'react';
import './../assets/scss/components/stackedCarousel.component.scss'
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";


const Item = ({ level, data, getValue, }: { level: any, data: any, getValue:any }) => {

  const handleVal = (val:any) => {
    return getValue(val)
  }

  return (
    <div onClick={()=>{handleVal(data.value)}}>
      <div
        className={`item level${level}`}
        style={{
          backgroundImage: `url(${data.img})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }} >
        <h3 className="absolute bottom-5 text-white font-semibold left-5">{data.title}</h3>
        <p className="absolute -bottom-14 font-medium left-5 text-left text-sm text-black">{data.description}</p>
      </div>
    </div>
  );
};


const StackedCarouselComponent = ({ items = [], getValue }: { items: any, getValue:any }) => {
  
  const [state, setState] = React.useState({
    active: 0,
    direction: '',
  });

  const moveLeft = React.useCallback(() => {
    setState((prevState) => ({
      active: prevState.active > 0 ? prevState.active - 1 : items.length - 1,
      direction: 'left',
    }));
  }, [items.length]);

  const moveRight = React.useCallback(() => {
    setState((prevState) => ({
      active: (prevState.active + 1) % items.length,
      direction: 'right',
    }));
  }, [items.length]);

  const generateItems = () => {
    const generatedItems = [];
    let level;

    for (let i = state.active - 1; i < state.active + 2; i++) {
      let index = i;
      if (i < 0) {
        index = items.length + i;
      } else if (i >= items.length) {
        index = i % items.length;
      }
      level = state.active - i;
      generatedItems.push(<Item getValue={getValue} key={index} data={items[index]} level={level} />);
    }
    return generatedItems;
  };

  return (
    <div id="carousel" className="noselect !mb-10">
      <div className="arrow arrow-left" onClick={moveLeft}>
        <RiArrowDropLeftLine />
      </div>
      <div>{generateItems()}</div>
      <div className="arrow arrow-right" onClick={moveRight}>
        <RiArrowDropRightLine />
      </div>
    </div>
  );
};


export default StackedCarouselComponent;
