import React from "react";

function SearchDashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.364"
      height="18.306"
      viewBox="0 0 18.364 18.306"
    >
      <g transform="translate(.15 .15)">
        <path
          stroke="#000"
          strokeWidth="0.3"
          d="M8.073 0a8.076 8.076 0 105.029 14.381l3.363 3.361a.9.9 0 101.272-1.271l-3.363-3.362A8.064 8.064 0 008.074 0zm0 1.794a6.282 6.282 0 11-6.279 6.279 6.264 6.264 0 016.279-6.279z"
          data-name="circle2017"
        ></path>
      </g>
    </svg>
  );
}

export default SearchDashboardIcon;
