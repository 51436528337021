import React from "react";
import { Link } from "react-router-dom";

const VerifiedAccountPage: React.FC = () => {
  const description =
    "We are pleased to confirm that your email address has been successfully validated and is currently being verified by our team.\nWe’ll get back to you shortly by email.";

  return (
    <div className="VerifiedAccountPage layoutAuth">
      <div id="VerifiedAccount">
        <div className="wrapper fl_col gp33">
          <div className="form_header fl_col gp40 ai_c">
            <p className="p32 w-700 txt_left">Confirmation</p>
            <p className="p14 w-500 txt_left fl_col gp20">
              <span>
                {" "}
                We are pleased to confirm that your email address has been
                successfully validated and is currently being verified by our
                team.
              </span>
              <span>We’ll get back to you shortly by email.</span>
            </p>
          </div>
          <div className="wrapper fl_col gp20">
            {/* <Link to={"/login"}>
              <button className="btn-light">Login</button>
            </Link> */}
            <Link to={"https://decadance.uteek.net/dashboard"}>
              <button className="btn-black">Go to the Home Page</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedAccountPage;
