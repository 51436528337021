import storeTypes from "store/types.store"
import { IReduxAction } from "../../shared/interfaces/reduxAction.interface"

const initialState = {
  list : []
}

const userReducer = (state = initialState, action:IReduxAction) => {
  switch (action.type) {
    case storeTypes.user.all:
      return {
        ...state,
        list:action.payload
      }
    default:
      return {...state}
  }
}

export default userReducer