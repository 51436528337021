import axios from 'axios';


// Add a request interceptor
axios.interceptors.request.use(
  (config:any) => {
    // Do something before request is sent
    //config.headers.Authorization = `Bearer ${your_token}`;
    config.baseURL = "https://api.decadance.uteek.net/api/";
    config.headers['Accept'] = 'application/json';
    //config.headers['Cache-Control'] = 'max-age=604800';
    //config.headers['Authorization'] = `Bearer pass`;
    //config.headers['x-access-token'] = `pass`;



    /* authGuard.getCookie().then((cookie:any) => {
      if(cookie !== undefined){
        const token = JSON.parse(cookie)
        config.headers['x-access-token'] = token
      }
    }) */

    return config;
  },
  (error:any) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

const methods = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
}

export default methods