import React from 'react';


function InstagramIcon() {
  return (
    <svg id="Group_2219"  viewBox="0 0 30 30">
      <defs>
        <linearGradient id="linear-gradient" x1="0.236" y1="0.113" x2="0.753" y2="0.91" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffd600" />
          <stop offset="0.5" stopColor="#ff0100" />
          <stop offset="1" stopColor="#d800b9" />
        </linearGradient>
      </defs>
      <path id="background" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" fill="url(#linear-gradient)" />
      <g id="instagram_14_" data-name="instagram (14)" transform="translate(6.668 6.668)">
        <path id="Path_24678" data-name="Path 24678" d="M16.528,4.871a6.085,6.085,0,0,0-.385-2.012,4.062,4.062,0,0,0-.956-1.468A4.063,4.063,0,0,0,13.718.435,6.084,6.084,0,0,0,11.706.05C10.822.01,10.54,0,8.289,0S5.755.01,4.871.05A6.086,6.086,0,0,0,2.859.435a4.062,4.062,0,0,0-1.468.956A4.063,4.063,0,0,0,.435,2.859,6.085,6.085,0,0,0,.05,4.871C.01,5.755,0,6.038,0,8.289s.01,2.534.05,3.417a6.084,6.084,0,0,0,.385,2.012,4.062,4.062,0,0,0,.956,1.468,4.061,4.061,0,0,0,1.468.956,6.082,6.082,0,0,0,2.012.385c.884.04,1.166.05,3.417.05s2.533-.009,3.417-.05a6.083,6.083,0,0,0,2.012-.385,4.238,4.238,0,0,0,2.424-2.424,6.083,6.083,0,0,0,.385-2.012c.04-.884.05-1.166.05-3.417S16.568,5.755,16.528,4.871Zm-1.492,6.767a4.586,4.586,0,0,1-.285,1.539,2.745,2.745,0,0,1-1.573,1.573,4.586,4.586,0,0,1-1.539.285c-.874.04-1.136.048-3.349.048s-2.476-.008-3.349-.048A4.588,4.588,0,0,1,3.4,14.75a2.569,2.569,0,0,1-.953-.62,2.568,2.568,0,0,1-.62-.953,4.586,4.586,0,0,1-.285-1.539c-.04-.874-.048-1.136-.048-3.349s.008-2.475.048-3.349A4.589,4.589,0,0,1,1.827,3.4a2.57,2.57,0,0,1,.62-.953,2.566,2.566,0,0,1,.953-.62,4.585,4.585,0,0,1,1.539-.285c.874-.04,1.136-.048,3.349-.048h0c2.213,0,2.475.008,3.349.048a4.587,4.587,0,0,1,1.539.285,2.57,2.57,0,0,1,.953.62,2.566,2.566,0,0,1,.62.953,4.582,4.582,0,0,1,.285,1.539c.04.874.048,1.136.048,3.349S15.076,10.764,15.036,11.638Zm0,0" transform="translate(0 0)" fill="#fff" />
        <path id="Path_24679" data-name="Path 24679" d="M128.8,124.539a4.256,4.256,0,1,0,4.256,4.256A4.256,4.256,0,0,0,128.8,124.539Zm0,7.019a2.763,2.763,0,1,1,2.763-2.763A2.763,2.763,0,0,1,128.8,131.558Zm0,0" transform="translate(-120.507 -120.507)" fill="#fff" />
        <path id="Path_24680" data-name="Path 24680" d="M363.919,89.62a.995.995,0,1,1-.995-.995A.995.995,0,0,1,363.919,89.62Zm0,0" transform="translate(-350.211 -85.756)" fill="#fff" />
      </g>
    </svg>


  )
}
export default InstagramIcon;
