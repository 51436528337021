import React from "react";
import { Button, Form, notification } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login, register } from "store/actions/auth.action";
import { useDispatch } from "react-redux";
import InputComponent from "components/Input.component";

const RegisterPage: React.FC = () => {

  const location = useLocation();
  const dispatch: any = useDispatch()
  const navigate = useNavigate()

  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const [registerForm, setRegisterForm] = React.useState({
    providers: [],
    profileType: '',
    username: '',
    email: '',
    password: '',
    passwordConfirm: '',
    conditions:''
  })

  const handleRegister = React.useCallback((values: any) => {
    const formData = { ...registerForm, ...values, ...location.state.register }
    console.log(formData);

    dispatch(register(formData, api, () => navigate('/verify', { state: { email: values.email, type: 'verification' } })))
  }, [dispatch])

  return (
    <div className="Login_auth fl_col jc_c">
      {contextHolder}

      <div className="form_header fl_col gp27 ai_c">
        <h1 className="p32 w-600 txt_center">Create account</h1>
        <p className="p14 w-500 txt_center">Let’s get your account set up.</p>
      </div>

      <div className="form_body pd40-t inp_light ">
        <Form
          form={form}
          className="fl_col gp16"
          layout="vertical"
          onKeyUp={(e) => (e.key === "Enter" ? form.submit() : null)}
          initialValues={registerForm}
          onFinish={handleRegister}
        >
          <div className="fl_col gp20">

            <InputComponent
              placeholder="Username"
              name="username"
              required
              rules={[{ required: true, message: "Username required" }]}
            />
            <InputComponent
              type="email"
              placeholder="Email"
              name="email"
              required
              rules={[{ required: true, message: 'Email required' }, { type: "email", message: "Email not valid" }]}
            />
            <InputComponent
              type="password"
              placeholder="Password"
              name="password"
              required
              rules={[{ required: true, message: "Password required" }]}
            />
            <InputComponent
              type="password"
              placeholder="Confirm Password"
              name="passwordConfirm"
              required
              rules={[
                { required: true, message: "Password required" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Passwords do not match")
                    );
                  },
                }),
              ]}
            />

            <div className="iagree fl_row gp5">
              <InputComponent type="checkbox" valuePropName="checked" name="conditions" rules={[
                  {
                    validator: async(_,checked:boolean)=>{
                      if (!checked) {
                        return Promise.reject(
                            new Error("Please accept our policies & conditions"),
                        );
                      }
                    }
                  }
                ]}>
                I agree to the{" "}
                <Link className="underline" to={"#terms_and_conditions"}>Terms and conditions</Link>{" "}
                and <Link className="underline" to={"#terms_and_conditions"}>Privacy Policy</Link>
              </InputComponent>
            </div>
            <Button className="btn-black" onClick={() => form.submit()} type="link" > Register </Button>
          </div>
        </Form>
      </div>
      <div className="form_footer fl_col pd35-t-b">
        <Link to="/login" className="p14 w-600 txt_center link no-underline text-black">
          Already have an account ?
        </Link>
      </div>
    </div>
  );
};

export default RegisterPage;
