
import { createBrowserRouter } from "react-router-dom";
import authRoutes from "./auth.routes";
import AuthLayout from "layouts/Auth.layout";
import HomePage from "pages/Home.page";
import DashboardLayout from "layouts/Dashboard.layout";
import privateRoutes from "./private.routes";

const router = createBrowserRouter([
  {
    index: true,
    element: <HomePage />,
  },
  {
    path:'',
    element:<AuthLayout />,
    children:authRoutes
  },
  {
    path:'dashboard',
    element:<DashboardLayout />,
    children:privateRoutes
  }
]);

export default router;