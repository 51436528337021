import React from 'react';


function EmailBlackIcon() {
    return (
        <svg id="email" xmlns="http://www.w3.org/2000/svg" width="19.975" height="14.716" viewBox="0 0 19.975 14.716">
        <g id="Group_2221" data-name="Group 2221" transform="translate(0 4.151)">
          <g id="Group_2220" data-name="Group 2220" transform="translate(0 0)">
            <path id="Path_24682" data-name="Path 24682" d="M10.565,152.931a1.135,1.135,0,0,1-1.179,0L0,147.2v7.617a2.949,2.949,0,0,0,2.948,2.948H17.027a2.949,2.949,0,0,0,2.948-2.948V147.2Z" transform="translate(0 -147.2)"/>
          </g>
        </g>
        <g id="Group_2223" data-name="Group 2223" transform="translate(0.094)">
          <g id="Group_2222" data-name="Group 2222" transform="translate(0 0)">
            <path id="Path_24683" data-name="Path 24683" d="M18.98,57.088H4.9A2.93,2.93,0,0,0,2.048,59.4l9.9,6.037L21.834,59.4A2.93,2.93,0,0,0,18.98,57.088Z" transform="translate(-2.048 -57.088)"/>
          </g>
        </g>
      </svg>


    )
}
export default EmailBlackIcon;
